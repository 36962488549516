// TODO: add tests for this file

const ScriptDefinition = require('./ScriptDefinition');
const ScriptLoader = require('./ScriptLoader');

// this is here because we can't use inline event handlers due to CSP, and we don't want to add another <script src> to script-loader.html page because what if it doesn't load (the purpose of script-loader is to provide fall-back for such cases)
document.addEventListener('click', function (event) {
    if (event.target.id === 'script-loader-reload-window') {
        window.location.reload();
    }
});

window.loadScripts = async (page, onloadCallback = undefined) => {
    const AngularJS = () => {
        onloadFunctions.push(() => angular.bootstrap(document, ['borderwise.app']));
        return new ScriptDefinition('Angular.js', '/assets/js/angular.js');
    };

    const getGlobalInitScripts = () => [
        // these will be loaded before any other scripts for all pages
        new ScriptDefinition('config', '/config.js'), // config needs to be loaded before Sentry, because window['__config__'] is used in Sentry initializations
        new ScriptDefinition('jQuery', '/assets/vendor/jquery/3.6.0/jquery.min.js'), // jQuery needs to be loaded before AngularJS
        AngularJS(), // AngularJS needs to be loaded before Sentry for AngularJS integration to work properly
        new ScriptDefinition('i18next', '/assets/vue-elements/i18next.js', { type: 'module' }), // defines i18next global object which is used by ng-i18next, so it needs to be loaded before ng-i18next
        new ScriptDefinition('ng-i18next', '/i18n/ng-i18next.min.js'), // ng-i18next needs to be loaded after AngularJS because it will register a provider for AngularJS
        new ScriptDefinition('Sentry', '/sentry/sentry.js'), // Sentry needs to be loaded before report-issue so that we have a chance to ignore false-positive network issues and not use up our free issues limit
        new ScriptDefinition('report-issue', '/assets/vue-elements/report-issue.js', { type: 'module' }), // Report-Issue script needs to be loaded after Sentry, so that we have control over what console.errors actually get printed and reported to Sentry
        new ScriptDefinition('vue-elements', '/assets/vue-elements/vue-elements.js', { type: 'module' }),
        new ScriptDefinition('init', '/assets/js/init.js'),
        new ScriptDefinition('reactivity', '/assets/js/reactivity.js'),
    ];

    let scripts = [],
        onloadFunctions = [onloadCallback];

    switch (page) {
        case 'app':
            scripts = [
                // TODO: maybe actually remove that friendly name, or at least move to the end?.. it kinda makes code a bit ugly
                new ScriptDefinition('mark.js', '/assets/vendor/mark.js/8.11.1/mark.min.js'),
                new ScriptDefinition('jQueryUI', '/assets/vendor/jqueryui/1.13.1/jquery-ui.min.js'),
                new ScriptDefinition('twitter-bootstrap', '/assets/vendor/twitter-bootstrap/3.4.1/js/bootstrap.min.js'),
                new ScriptDefinition('bootstrap-notify', '/assets/vendor/mouse0270-bootstrap-notify/3.1.5/bootstrap-notify.min.js'),
                new ScriptDefinition('angular-sanitize', '/assets/vendor/angular-sanitize/1.8.2/angular-sanitize.min.js'),
                new ScriptDefinition('angular-ui-sortable', '/assets/vendor/angular-ui-sortable/0.15.0/sortable.min.js'),
                new ScriptDefinition('angular-loading-bar', '/assets/vendor/angular-loading-bar/0.9.0/loading-bar.min.js'),
                new ScriptDefinition('moment', '/assets/vendor/moment/2.15.0/js/moment.js'),
                new ScriptDefinition('dedent', '/assets/vendor/dedent/0.7.0/dedent.js'),
                new ScriptDefinition('highcharts', '/assets/vendor/highcharts/5.0.11/highstock.js'),
                new ScriptDefinition('bootstrap-datepicker', '/assets/vendor/bootstrap-datepicker/1.10.0/js/bootstrap-datepicker.min.js'),
                new ScriptDefinition('underscore.js', '/assets/vendor/underscore.js/1.9.1/underscore-min.js'),
                new ScriptDefinition('imagesloaded', '/assets/vendor/imagesloaded/4.1.4/imagesloaded.pkgd.min.js'), //cspell:words imagesloaded pkgd
                new ScriptDefinition('UAParser.js', '/assets/vendor/uap/uap.js'),
                new ScriptDefinition('compatibility', '/assets/vendor/compatibility/compatibility.js'),
                new ScriptDefinition('jQuery Plugins', '/assets/js/jquery.plugins.js'),
                new ScriptDefinition('jQuery Highlight', '/assets/vendor/Johann/jquery.highlight-5.js'),
                new ScriptDefinition('App Script', '/assets/js/script.app.js'),
                new ScriptDefinition('regexgen', '/assets/vendor/regexgen/regexgen.min.js'),
                new ScriptDefinition('angularjs-dropdown-multiselect', '/assets/vendor/angularjs-dropdown-multiselect/2.0.0-beta.10/angularjs-dropdown-multiselect.min.js'),
                new ScriptDefinition('angular-resizable', '/assets/vendor/angular-resizable/angular-resizable.min.js'),
                new ScriptDefinition('App', '/app/app.js'),
            ];
            break;
        default:
            throw `unknown page ${page}`;
    }

    const onload = () => {
        onloadFunctions.forEach((fn) => {
            if (typeof fn === 'function') {
                fn();
            }
        });
    };

    const scriptLoader = new ScriptLoader([...getGlobalInitScripts(), ...scripts], onload);
    await scriptLoader.startLoading();
    await scriptLoader.checkStatus();
};
